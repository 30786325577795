<template>
  <div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>
          <i class="el-icon-s-home"></i>
          首页
        </el-breadcrumb-item>
        <el-breadcrumb-item>收款统计表</el-breadcrumb-item>
      </el-breadcrumb>
      <el-button type="success" style="height: 36px" @click="reload">
        <i style="font-size: 18px" class="el-icon-refresh-right"></i>
      </el-button>
    </div>
    <div
      style="display: flex; align-items: center; padding: 20px; font-size: 14px"
    >
      <el-row>
        <el-col
          :span="7"
          style="display: flex; align-items: center; white-space: nowrap"
        >
          <span>完成时间：</span>
          <el-date-picker
            v-model="time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-col>
        <el-col
          :span="5"
          style="display: flex; align-items: center; white-space: nowrap"
        >
          <span>选择水站：</span>
          <el-select
            v-model="option1"
            clearable
            filterable
            placeholder="选择水站"
          >
            <el-option
              v-for="item in option1es"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="7" style="display: flex; align-items: center">
          <el-button type="success" size="mini">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img
                style="width: 16px; height: 16px"
                :src="require('@/assets/iconImg/logo.png')"
              />
              <span>查询</span>
            </div>
          </el-button>
          <el-button type="success" size="mini">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img
                style="width: 16px; height: 16px"
                :src="require('@/assets/iconImg/logo.png')"
              />
              <span>清楚</span>
            </div>
          </el-button>
          <el-button type="primary" size="mini">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img
                style="width: 16px; height: 16px"
                :src="require('@/assets/iconImg/logo.png')"
              />
              <span>导出报表</span>
            </div>
          </el-button>
        </el-col>
      </el-row>
    </div>
    <div style="padding: 20px">
      <el-table :data="tableData" border show-summary style="width: 100%">
        <el-table-column prop="name" label="站名"> </el-table-column>
        <el-table-column prop="receivablePaid" label="应收金额">
        </el-table-column>
        <el-table-column prop="amountPaid" label="实收金额"> </el-table-column>
        <el-table-column prop="cash" label="现金"> </el-table-column>
        <el-table-column prop="WeChat" label="微信"> </el-table-column>
        <el-table-column prop="Alipay" label="支付宝"> </el-table-column>
        <el-table-column prop="disbursementPaid" label="支出金额">
        </el-table-column>
        <el-table-column prop="waterPaid" label="水票抵扣金额">
        </el-table-column>
        <el-table-column prop="currentArrears" label="当前欠款">
        </el-table-column>
        <el-table-column prop="repayment" label="还款"> </el-table-column>
        <el-table-column prop="details" label="详情"> </el-table-column>
      </el-table>
      <div class="tableBottom">
        <span v-if="isResult" style=""
          >显示第&nbsp;1&nbsp;至&nbsp;{{
            tableFinshNum
          }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span
        >
        <span v-if="!isResult" style=""
          >显示&nbsp;{{ tableFinshNum }}&nbsp;项结果，共&nbsp;{{
            tableFinshNum
          }}&nbsp;项</span
        >
        <div
          style="display: flex; justify-content: flex-end; align-items: center"
        >
          <el-button>首页</el-button>
          <el-button>上页</el-button>
          <el-pagination
            background
            layout=" pager,slot"
            :total="1"
          ></el-pagination>
          <el-button>下页</el-button>
          <el-button>末页</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      time: "",

      option1: "",
      option1es: [],

      tableData: [],
      isResult: false,
      tableFinshNum: 0,
    };
  },
  mounted() {
    if (this.tableData.length == 0) {
      this.isResult = false;
    } else {
      this.isResult = true;
      this.tableFinshNum = this.tableData.length;
    }
  },
  computed: {},
  methods: {
    reload() {
      location.reload();
    },
    handleEdit() {},
    handleDelete() {},
  },
};
</script>
<style scoped lang="less">
.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}

/deep/ .el-col {
  display: flex;
  align-items: center;
  margin: 0 5px;
}
</style>
